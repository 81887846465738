<template>
    <div class="dialog-add-promo" v-loading="loading">
      <div class="dialog-add-promo__title" v-html="promoTitle">
      </div>
      <div class="dialog-add-promo__desc">
        {{ $t('business_account_data.description') }}
      </div>
      <div class="dialog-add-promo__sections">
        <div class="dialog-add-promo-section">
          <div class="dialog-add-promo-section__title">
            {{ $t('promo_data.promo_name') }}
          </div>
          <div class="dialog-add-promo-section__description">
            {{ $t('promo_data.create') }}
          </div>
          <form class="dialog-add-promo-section__form">
            <div class="dialog-add-promo-section__form-block">
              <div class="dialog-add-promo-section__form-row">
                <div  class="dialog-add-promo-section__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input" :class="[{'form-input--full' : form.promo}, {'form-input--error' : errors.promo}]">
                      <input 
                        v-model="form.promo"
                        v-on:keydown.enter.prevent 
                        type="text" 
                        @input="checkPromo"
                        @blur="checkPromo"
                        required
                        :readonly="promoTrigger==='PromoTable'"
                        >
                      <span class="form-input__label"> {{ $t('promo_data.promo_input-promo') }}</span>
                      <div v-if="errors.promo" class="form-input__error" v-text="errors.promo" />
                    </div>
                  </div>
                </div>
                <div class="dialog-add-promo-section__form-col">
                  <ButtonElement
                    :text="$t('promo_data.promo_generate-promo')"
                    @click-button="generateCodeServer"
                    :disabled="promoTrigger==='PromoTable'"
                  />
                </div>
              </div>
            </div>
          </form>
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.for_whom') }}
        </div>
        <div class="dialog-add-promo-section__checkbox">
          <label class="input__item-checkbox">
          <input
              type="checkbox"
              value="all"
              required
              v-model="form.is_personal"
          >
          <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
          <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('promo_data.personal') }}
            </span>
          </span>
          </label>
        </div>
      </div>
      <div v-if="!form.is_personal" class="dialog-add-promo-section">
        <div class = "dialog-add-promo-section__title">
          {{ $t('promo_data.account_type') }}
        </div>
        <div class="dialog-add-promo-section__selector" >
          <Selector
            v-if="locale==='RU'"
            title="Укажите тип бизнесаккаунта"
            :options="clientTypes"
            @handleChange="setClientType"
            :is_for_legals="editPromo && editPromo.is_for_legals && editPromo.is_for_legals === null ? 0: (editPromo && editPromo.is_for_legals && editPromo.is_for_legals === 1?'legal': (editPromo && editPromo.is_for_legals === 0? 'individual': 0))"
          />
          <Selector
            v-if="locale==='USA'"
            title="Specify your business account type"
            :options="clientTypesEN"
            @handleChange="setClientType"
            :is_for_legals="editPromo && editPromo.is_for_legals && editPromo.is_for_legals === null ? 0: (editPromo && editPromo.is_for_legals && editPromo.is_for_legals === 1?'legal': (editPromo && editPromo.is_for_legals === 0? 'individual': 0))"
          />
        </div>
        <div class="dialog-add-promo-section__checkbox">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                value="all"
                required
                v-model="form.disposable"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('promo_data.disposable') }}
              </span>
            </span>
          </label>
        </div>
      </div>
      <div v-if="form.is_personal" class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__description">
          {{ $t('promo_data.choose_business_account') }}
        </div>
        <div v-if="errors.selectedAccounts" class="form-input__error" v-text="errors.selectedAccounts" style="margin-bottom:-1.5rem; margin-top: -1.5rem;" />
        <div class="dialog-add-promo-section__selector">
          <div class="dialog-add-promo-section__selector-wrapper">
           <!-- <button v-if="key === selectors.length-1" @click="incrementSelectors(selector)" class="promo-button"></button> -->
           <div class="selector multi-search-selector">
              <div class="selected-accounts">
                <div v-for="item in selectedAccounts" :key="item.id" class="selected-accounts__item selected-accounts__item--admin">
                  <div class="selected-accounts__item-name" v-html="item.name && item.name.length > 0 ? item.name: item.email" />
                  <div class="selected-accounts__item-dell" @click="dellAcc(item.id)">
                    <img src="@/assets/icons/icon-vk-acc-dell.svg">
                  </div>
                </div>
              </div>
              <div class="selector__content" @click="showOptions = !showOptions">
                <div class="selector__title">
                  {{ $t('promo_data.business_accounts') }}
                </div>
                <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
                  <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
                </div>
              </div>
              <div v-if="showOptions" class="selector__options vk-selector">
                <div
                    class="form-input selector__options-search"
                    :class="
                  [
                    {'form-input--full' : search}
                  ]"
                >
                  <input v-model="search" type="text" required>
                  <span class="form-input__label">
                    {{ $t('search_props.placeholder') }}
                  </span>
                </div>
                <div
                    v-for="account in filteredAccounts"
                    :key="account.id"
                    class="selector__options-item"
                    :class="{'selector__options-item--active': checkStatus(account.id)}"
                    @click="chooseAccount(account.name, account.id, account.email)"
                >
                  <div v-html="account.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.promotional_amount') }}
        </div>
        <div class="promo-values-group">
          <label class="promo-amount" :class="{active: form.promoValue==1000}">
            <input  name="promo" type="radio" v-model="form.promoValue" value="1000">
            <span>
              1000
            </span>
          </label>
          <label class="promo-amount" :class="{active: form.promoValue==2000}">
            <input  name="promo" type="radio" v-model="form.promoValue" value="2000">
            <span>
              2000
            </span>
          </label>
          <label class="promo-amount" :class="{active: form.promoValue==3000}">
            <input  name="promo" type="radio" v-model="form.promoValue" value="3000">
            <span>
              3000
            </span>
          </label>
          <label class="promo-amount" :class="{active: form.promoValue==5000}">
            <input  name="promo" type="radio" v-model="form.promoValue" value="5000">
            <span>
              5000
            </span>
          </label>
          <label class="promo-amount" :class="{active: form.promoValue==10000}">
            <input  name="promo" type="radio" v-model="form.promoValue" value="10000">
            <span>
              10000
            </span>
          </label>
          <div
            v-if="errors.promoValue"
            class="form-input__error"
            v-text="errors.promoValue"
          />
          </div>
        </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.promo_condition') }}
        </div>
        <div class="dialog-add-promo-section__description">
          {{ $t('promo_data.choose_condition') }}
        </div>
        <div
            class="form-input dialog-add-promo-section__form-input"
            :class="[{'form-input--full' : form.condition_sum}, {'form-input--error' : errors.condition_sum}]"
          >
            <input v-model="form.condition_sum" type="number" inputmode="numeric" pattern="[0-9]*">
            <span class="form-input__label">{{ $t('promo_data.month_achive') }}</span>
            <div
              v-if="errors.condition_sum"
              class="form-input__error"
              v-text="errors.condition_sum"
            />
        </div>
        <div class="dialog-add-promo-section__checkbox">
          <label class="input__item-checkbox">
          <input
              type="checkbox"
              value="all"
              v-model="form.after_replenishment"
          >
          <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
          <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('promo_data.after_replenishment') }}
            </span>
          </span>
          </label>
        </div>
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.active_period') }}
        </div>
        <div class="dialog-add-promo-section__selector">
          <Selector
            v-if="locale==='RU'"
            :title="$t('promo_data.period')"
            :options="promoPeriods"
            @handleChange="setPromoPeriod"
            :sum_available_in="editPromo && editPromo.sum_available_in ? editPromo.sum_available_in :null"
          />
          <Selector
            v-if="locale==='USA'"
            :title="$t('promo_data.period')"
            :options="promoPeriodsUSA"
            @handleChange="setPromoPeriod"
            :sum_available_in="editPromo && editPromo.sum_available_in ? editPromo.sum_available_in :null"
          />
        </div>
      </div>
      <div v-if="promoTrigger !=='PromoTable'" class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{$t('promo_data.promo_description')}}
        </div>
        <div class="dialog-add-promo-section__description">
          {{ $t('promo_data.choose_and_edit') }}
        </div>
        <div class="promo-values-group">
          <label class="promo-amount" :class="{active: promo_template==1}">
            <input  name="promo" type="radio" v-model="promo_template" value="1">
            <span>
              {{ $t('promo_data.template') }} 1
            </span>
          </label>
          <label class="promo-amount" :class="{active: promo_template==2}">
            <input  name="promo" type="radio" v-model="promo_template" value="2">
            <span>
              {{ $t('promo_data.template') }} 2
            </span>
          </label>
        </div>
        <textarea v-if="promo_template == 1" class="dialog-add-promo-section__info" v-model="description_1">
        
        </textarea>
        <textarea v-if="promo_template == 2" class="dialog-add-promo-section__info" v-model="description_2">
         
        </textarea>
      </div>
      <div v-if="promoTrigger ==='PromoTable'" class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.promo_description') }}
        </div>
        <textarea class="dialog-add-promo-section__info" v-model="form.description"></textarea>
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.promo_validity') }}
        </div>
        <DatePicker
          :title="$t('promo_data.date')"
          :use-timezone="true"
          @changeDates="changeDate"
          :startPeriod="editPromo && editPromo.available_at ? editPromo.available_at : ''"
          :endPeriod="editPromo && editPromo.expired_at ? editPromo.expired_at: ''"
        />
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__title">
          {{ $t('promo_data.note') }}
        </div>
        <div
            class="form-input dialog-add-promo-section__form-input"
            :class="[{'form-input--full' : form.remark}, {'form-input--error' : errors.remark}]"
          >
            <input v-model="form.remark" type="text">
            <span class="form-input__label">{{ $t('promo_data.additional_info') }}</span>
            <div
              v-if="errors.remark"
              class="form-input__error"
              v-text="errors.remark"
            />
        </div>
        <div class="dialog-add-promo-section__description">
          {{ $t('promo_data.additional_info_example') }}
        </div>
      </div>
      <div v-if="isError" class="form-input__error">
        <p v-for="(err, index) in errors" :key="index">
            {{ err }}
        </p>         
      </div>
      <div class="dialog-add-promo-section">
        <div class="dialog-add-promo-section__form-col dialog-add-promo-section__form-col--padding-0">
          <ButtonElement
            :text="$t('promo_data.promo_save')"
            @click-button="savePromocode"
            :disabled="buttonDisabled || !!errors.promo ||!!errors.promoValue || !!errors.remark || !!errors.selectedAccounts || !!errors.sum_available || !!errors.condition_sum"
          />
          </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import formValidate from '../../mixin/formValidate'
  import ButtonElement from '../../components/elements/ButtonElement'
  import Selector from "@/components/sort/Selector";
  import DatePicker from "@/components/sort/DatePicker";
  import axios from 'axios';

  const apiUrl = process.env.VUE_APP_API_URL

  export default {
    name: 'PromoData',
    components: {
      ButtonElement,
      Selector,
      DatePicker,
    },
    mixins: [formValidate],
    data() {
      return {
        showOptions: false,
        selectedAccounts: null,
        deletedAccounts: null,
        search:'',
        selectors: [1],
        rawClients:[],
        lang: this.$i18n._vm.locale,
        isShow: false,
        locale: process.env.VUE_APP_LOCALE,
        loading: false,
        inputAddressDisabled: false,
        isDisabledLegalAddress: false,
        promo_template: 1,
        monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
        selectedDate: null,
        today: new Date().toISOString().slice(0, 10),
        text_2:'на счет кэшбека сразу после активации. Промосумма может быть использована только для пополнения бюджета рекламных площадок и доступна в течение 12 месяцев после активации промокода. Вывод промосуммы на расчетный счет – невозможен. Данный промокод начисляет дополнительные 1000 руб.',
        text_1:'Данный промокод начисляет дополнительные 1000 руб. на счет кэшбека сразу после активации. Промосумма может быть использована только для пополнения бюджета рекламных площадок и доступна в течение 12 месяцев после активации промокода. Вывод промосуммы на расчетный счет – невозможен.',
        business_accounts:[],
        buttonDisabled: false,
        form: {
          promo:'',
          remark:'',
          disposable:false,
          promoValue: '',
          is_for_legals: null,
          is_personal: false,
          sum_available_in:3,
          description:'',
          condition_sum: '',
          business_accounts:[],
          available_at: '',
          expired_at: '',
          after_replenishment: false
        },
        errors: {
          promo: '',
          selectedAccounts: '',
          promoValue: '',
          sum_available: '',
          remark:'',
          api_error: '',
          condition_sum: ''
        },
        clientTypes: [
          {
            id: 0,
            name: 'Все'
          },
          {
            id: 'legal',
            name: 'Юрлицо'
          },
          {
            id: 'individual',
            name: 'Физлицо'
          },
        ],
        clientTypesEN: [
          {
            id: 0,
            name: 'All'
          },
          {
            id: 'legal',
            name: 'Legal'
          },
          {
            id: 'individual',
            name: 'Individual'
          },
        ],
        promoPeriods: [
          {
            id: 3,
            name: '3 месяца',
            value: 3
          },  
          {
            id: 6,
            name: '6 месяцев',
            value: 6
          },
          {
            id: 9,
            name: '9 месяцев',
            value: 9
          },
          {
            id: 12,
            name: '12 месяцев',
            value: 12
          },
        ],
        promoPeriodsUSA: [
          {
            id: 3,
            name: '3 months',
            value: 3
          },  
          {
            id: 6,
            name: '6 months',
            value: 6
          },
          {
            id: 9,
            name: '9 months',
            value: 9
          },
          {
            id: 12,
            name: '12 months',
            value: 12
          },
        ],
        startDate: new Date(),
        endDate: new Date(),
        date: {
          startDate: new Date(),
          endDate: new Date()
        },
        localeEn: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
        monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
        firstDay: 1
      },
      localeRu: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
        firstDay: 1
      },
      monthsRu: [
        {
          id: 0,
          shortName: 'янв'
        },
        {
          id: 1,
          shortName: 'фев'
        },
        {
          id: 2,
          shortName: 'мар'
        },
        {
          id: 3,
          shortName: 'апр'
        },
        {
          id: 4,
          shortName: 'май'
        },
        {
          id: 5,
          shortName: 'июн'
        },
        {
          id: 6,
          shortName: 'июл'
        },
        {
          id: 7,
          shortName: 'авг'
        },
        {
          id: 8,
          shortName: 'сен'
        },
        {
          id: 9,
          shortName: 'окт'
        },
        {
          id: 10,
          shortName: 'нояб'
        },
        {
          id: 11,
          shortName: 'дек'
        }
      ],
      monthsEn: [
        {
          id: 0,
          shortName: 'jan'
        },
        {
          id: 1,
          shortName: 'feb'
        },
        {
          id: 2,
          shortName: 'mar'
        },
        {
          id: 3,
          shortName: 'apr'
        },
        {
          id: 4,
          shortName: 'may'
        },
        {
          id: 5,
          shortName: 'jun'
        },
        {
          id: 6,
          shortName: 'jul'
        },
        {
          id: 7,
          shortName: 'aug'
        },
        {
          id: 8,
          shortName: 'sep'
        },
        {
          id: 9,
          shortName: 'oct'
        },
        {
          id: 10,
          shortName: 'nov'
        },
        {
          id: 11,
          shortName: 'dec'
        }
      ],

      }
    },
    computed: {
      description_1() {
        return `${this.$t('promo_data.description_1.0')} ${this.form.promoValue} ${this.$t('promo_data.description_1.1')}`
      },
      description_2() {
        return `${this.$t('promo_data.description_2.0')} ${this.form.promoValue} ${this.$t('promo_data.description_2.1')} ${this.form.condition_sum}  ${this.$t('promo_data.description_2.2')} ${this.form.sum_available_in} ${this.$t('promo_data.description_2.3')}`
      },
      sum_available_in() {
        return this.editPromo && this.editPromo.sum_available_in ? this.editPromo.sum_available_in : null
      },
      promoTitle() {
        if(this.promoTrigger ==='ActivePromo') {
          return this.$t('promo_data.title')
        } else if(this.promoTrigger ==='PromoTable'){
          return this.$t('promo_data.title_edit')
        }
        return ''
      },
      editPromo() {
        return this.$store.state.promo.editPromo
      },
      formatDate() {
        if(this.editPromo) {
          return {
            startDate: this.incomStart,
            endDate: this.incomEnd
          }
        }
        return ''
      },  
      incomingDate() {
        if(this.editPromo) {
          return {
            startDate: this.incomStart,
            endDate: this.incomEnd
          }
        }
        return ''
      },
      incomStart() {
        if(this.editPromo) {
          const [year, month, day] = this.editPromo.available_at.split('-');
          const date = new Date(Date.UTC(year, month - 1, day));
          return date
        }
        return ''
      },
      incomEnd() {
        if(this.editPromo) {
          const [year, month, day] = this.editPromo.expired_at.split('-');
          const date = new Date(Date.UTC(year, month - 1, day));
          return date
        }
        return ''
      },
      activeDialog(){
        return this.$store.state.Dialog.activeDialog
      },
      promoTrigger(){
        return this.$store.state.Dialog.promoTrigger
      },
      isError() {
        let error = false
        for (const key in this.errors) {
          if (this.errors[key]) error = true
        }
        return error
      },
      clients() {
       return this.rawClients.reduce((acc, item)=>{
          acc.push({id: item.id,
                    email: item.email,
                    //name: !item.is_legal ? `${item.first_name} ${item.last_name}` : item.legal_name,
                    name: item.name
                  })
          return acc
        },[])
      },

      filteredAccounts() {
        if (this.search && this.clients) {
          return this.clients.filter( el => 
            (el.name && el.name.toLowerCase().includes(this.search.toLowerCase()))
          )
        } else if (this.clients) {
          return this.clients
        }
        return []
      },

      optionsUsers() {
        const optionsUsers = []
          if (this.$store.state.Users.usersList && this.$store.state.Users.usersList.length) {
            const users = this.$store.state.Users.usersList
            users.forEach(user => {
              if (user.is_active) {
                optionsUsers.push({
                  id: user.id,
                  name: !user.is_legal ? `${user.first_name} ${user.last_name}` : user.legal_name,
                })
              }
            });
          }
        return optionsUsers
      },

      isEditable() {
        return this.$store.state.Dialog.isEditable
      },
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      },
      user() {
        return this.$store.state.Auth.user
      },
      role() {
        return this.$store.state.Auth.role
      },
      usersList() {
        return this.$store.state.Users.usersList
      },
      formPromo() {
        return this.form.promo
      },
      formPersonal() {
        return this.form.is_personal
      }
    },
    watch: {
      promo_template: {
        handler(val) {
          if (val) {
            this.setDescription(val)
          }
        },
      deep: true 
      },
      description_1: {
        handler(val) {
          if (val) {
            this.form.description = this.description_1
          }
        },
      deep: true 
      },
      description_2: {
        handler(val) {
          if (val) {
            this.form.description = this.description_2
          }
        },
      deep: true 
      },
      selectedAccounts: {
        handler(val) {
          this.$nextTick(()=>{
            if(this.form.is_personal && !val.length) {
              this.errors.selectedAccounts = this.$t('promo_data.error_business_account')
            }
          })
          if (val) {
          this.form.business_accounts = val.map((item) => item.id)
            //  this.form.business_accounts = val.reduce((acc, item)=>{
            //   const obj = {id: item.id, email: item.email}
            //   acc.push(obj)
            //   return acc
            //  },[])
          }
        },
        deep: true
      },
      formPromo() {
        this.checkPromo()
      },

      formPersonal: {
        handler(value) {
          if(value) {
            this.$nextTick(()=>{
              this.validateBusinessAccounts()
            })  
          }
          // if(value && (!this.selectedAccounts || !this.selectedAccounts.length)) {
          //   this.errors.selectedAccounts = 'Выберите хотябы один бизнес аккаунт'
          // }
        },
        deep: true
      }
    },
    mounted() {
      this.getData()
      this.setDescription(this.promo_template)
      // this.checkPromo()
      this.form.promo = this.editPromo ? this.editPromo.code : ''
      this.form.is_personal = this.editPromo && this.editPromo.business_accounts && this.editPromo.business_accounts.length ? true : false 
      this.selectedAccounts = this.editPromo && this.editPromo.business_accounts && this.editPromo.business_accounts.length ? [...this.editPromo.business_accounts]: null
      this.form.promoValue = this.editPromo ? this.editPromo.sum : ''
      this.form.condition_sum = this.editPromo ? this.editPromo.condition_sum : ''
      this.form.sum_available_in = this.editPromo ? this.editPromo.sum_available_in : 3
      this.form.description = this.editPromo ? this.editPromo.description : this.description_1
      if(this.editPromo) this.form.available_at = this.editPromo.available_at
      if(this.editPromo)  this.form.expired_at = this.editPromo.expired_at
      if(this.editPromo)  this.form.after_replenishment = this.editPromo.after_replenishment || false
      // this.form.available_at = this.editPromo ? this.editPromo.available_at : null
      // this.form.expired_at = this.editPromo ? this.editPromo.expired_at: null
      this.form.remark = this.editPromo ? this.editPromo.remark: ''
      this.form.disposable = this.editPromo && this.editPromo.disposable === 0 ? false : this.editPromo && this.editPromo.disposable === 1 ? true : false 
    },
    methods: {
      parseDate(dateString) {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Date.UTC(year, month - 1, day));
        return date;
      },
      validateBusinessAccounts() {
        if(!this.selectedAccounts || !this.selectedAccounts.length) this.errors.selectedAccounts = this.$t('promo_data.error_business_account')
      },
      validatePromo(value) {
        let regex = /[а-яА-ЯёЁ\W]+/
        return regex.test(value)
      },
      checkPromo() {
        if(!this.form.promo || !this.form.promo.length || this.form.promo.length < 6 || this.form.promo.length > 10 ) {
   
          this.errors.promo = this.$t('prepaid.promo-length-error')
          // this.buttonDisabled = true
        }
        else if (this.validatePromo(this.form.promo)) {
        
          this.errors.promo = this.$t('prepaid.promo-value-error')
          // this.buttonDisabled = true
        }
        else {
          this.buttonDisabled = false
        }
      },
      setDescription(val) {
        if(val === '1') {
              this.form.description = this.description_1
              
        }
        if(val === '2') {
              this.form.description = this.description_2
             
        }
      },
      chooseAccount(name, id, email) {
        if (this.deletedAccounts) {
          const index = this.deletedAccounts.findIndex(el => el === id)
          if (index !== -1) {
            this.deletedAccounts.splice(index, 1)
          }
        }
        if (!this.selectedAccounts) { 
          this.selectedAccounts = [{ name, id, email }] 
        } else {
          const index = this.selectedAccounts.findIndex(el => el.id === id)
          if (index === -1) {
            this.selectedAccounts.push(
              { name, id, email }
            )
          } else {
            this.dellAcc(id)
          }
        }
      },
      checkStatus(id) {
        if(this.selectedAccounts) {
          const checked = this.selectedAccounts.findIndex(el => el.id === id)
          return checked === -1 ? false : true
        }
      },
      dellAcc(id) {
        if (!this.deletedAccounts) { 
          this.deletedAccounts = [id] 
        } else {
          this.deletedAccounts.push(id) 
        }
        const index = this.selectedAccounts.findIndex(el => el.id === id)
        if (index !== -1) {
          this.selectedAccounts.splice(index, 1)
        }
      },

      generateCodeServer() {
        const token = localStorage.getItem('token')
        const data = {
          token:token
        }
        this.$store.dispatch('promo/generatePromo', data)
        .then(response => {
          this.form.promo = response.data.data.code
        })
        .catch(error => {
          this.errors.promo = error.message
        })
      },

      savePromocode () {
        // this.formValidation()
        if(!this.form.promo) {
          this.errors.promo = this.$t('promo_data.error_input_promo')
          return
        } else if(!this.form.promo || !this.form.promo.length || this.form.promo.length < 6 || this.form.promo.length > 10 ) {
          this.errors.promo = this.$t('prepaid.promo-length-error')
          // this.buttonDisabled = true
          
        } else if (this.validatePromo(this.form.promo)) {
          this.errors.promo = this.$t('prepaid.promo-value-error')
          // this.buttonDisabled = true
          
        }
        if(this.form.is_personal) {
          if(!this.form.business_accounts || !this.form.business_accounts.length ) {
            this.errors.selectedAccounts = this.$t('promo_data.error_business_account')
            // this.buttonDisabled = true

          }
        }
        if(!this.form.promoValue) {
          this.errors.promoValue = this.$t('promo_data.error_promosum')
          return
        }
        // if(!this.form.condition_sum) {
        //   this.errors.condition_sum ='Укажите условия работы промокода'
        //   // this.buttonDisabled = true
        //   return
        // } else 

        if(this.form.condition_sum && this.form.condition_sum.length) {
          let inputValue = this.form.condition_sum.trim()
          let number = /^[1-9]\d*$/.test(inputValue)
          if(!number) {
            this.errors.condition_sum = this.$t('promo_data.error_condition_sum')
            // this.buttonDisabled = true
            return
          }
        }
        if (!this.form.available_at && !this.form.expired_at) {
          this.errors.sum_available = this.$t('promo_data.error_sum_available')
          // this.buttonDisabled = true
          return
        }
        if(!this.form.remark || !this.form.remark.length) {
          this.errors.remark = this.$t('promo_data.error_remark')
          // this.buttonDisabled = true
          return
        } else if(this.form.remark) {
            if(!isNaN(this.form.remark)) {
              this.errors.remark = this.$t('promo_data.error_remark_string')
              // this.buttonDisabled = true
              return
            } 
        }
       
        const token = localStorage.getItem('token')
        const data = {
          token:token,
          code: this.form.promo,
          is_for_legals: this.form.is_for_legals,
          sum: this.form.promoValue,
          sum_available_in: this.form.sum_available_in,
          description: this.form.description,
          remark: this.form.remark,
          condition_sum: this.form.condition_sum !==''?this.form.condition_sum: 0,
          business_accounts: this.form.business_accounts,
          available_at: this.form.available_at,
          expired_at: this.form.expired_at,
          disposable: this.form.disposable,
          after_replenishment: this.form.after_replenishment,
          id: this.editPromo &&  this.editPromo.id ? this.editPromo.id: null
        }

          if(this.promoTrigger === 'ActivePromo') {
            this.$store.dispatch('promo/savePromo', data)
            .then(()=> {
                this.$notify({
                  message: this.$t('promo_data.promo_created'),
                  type: 'success'
                })
                this.closeDialog()
            })
            .catch(err => {
              console.error(err.message)
              this.$notify({
                  message: err.message,
                  type: 'error'
                })
              this.errors.api_error = err.message  
            })
          }

          if(this.promoTrigger ==='PromoTable') {
            this.$store.dispatch('promo/updatePromo', data)
            .then((resp)=> {
              this.$store.commit('promo/setData', { label: 'updatedPromoId', data: resp.data.data.id})
                this.$notify({
                  message: this.$t('promo_data.promo_updated'),
                  type: 'success'
                })
                this.closeDialog()
            })
            .catch(err => {
              console.error(err.message)
              this.$notify({
                  message: err.message,
                  type: 'error'
                })
              this.errors.api_error = err.message  
            })
          }

        // axios({ url: `${apiUrl}/admin/promocode`,
        //   method: 'POST',
        //   data:data,
        //   headers: { 'Authorization': `Bearer ${token}` }
        // })
        // this.closeDialog()
      },
      // removeSelected(value) {
      //   console.log(value)
      // },
      removeOption(value) {
        this.business_accounts = this.business_accounts.filter(el => el!== value[0].id)
      },
      setBusinessAccounts(value){
        if (value && value.length > 0) {
          this.form.business_accounts.push(value[0].id)
          this.business_accounts.push(value[0].id)
        }
      },
      setPromoPeriod(value) {
        this.form.sum_available_in = value
      },
      setClientType(value) {
        // if(value=== 'legal') {
        //   this.form.is_for_legals = true
        // }
        // if(value=== 'individual') {
        //   this.form.is_for_legals = false
        // } 
        // if(value=== 0) {
        //   this.form.is_for_legals = null
        // }
        this.form.is_for_legals = value === 0?null:value === 'individual'?false:value=== 'legal'
      },
      incrementSelectors(item) {
        this.selectors.push(++item)
      },
      getData() {
        this.loading = true
        const token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
          axios({
            url: `${apiUrl}/admin/clients`,
            method: 'GET',
            params: {per_page: 1000, is_short:1},
            headers: { 'Authorization': `Bearer ${token}` }
          })
          .then(resp => {
            this.loading = false
            this.rawClients = resp.data.data
          })
          .catch(err => {
            console.log(err.response)
            reject(err)
          })
          .finally(()=> this.loading = false)
        })
      },

      // getPromoCode () {
      //   const token = localStorage.getItem('token')
      //   axios({ url: `${apiUrl}/admin/promocode`,
      //     method: 'POST',
      //     data:{
      //     "code": "6565454",
      //     "is_for_legals": true,
      //     "sum": 9999,
      //     "sum_available_in": 6,
      //     "description": "Hot promo",
      //     "remark": "Hot promo",
      //     "condition_sum": 100000,
      //     "business_accounts": [
      //       "0db5741b-6dbc-4c67-90a5-3fc490861555"
      //     ],
      //     "available_at": "2023-09-15",
      //     "expired_at": "2023-12-15"
      //     },
      //     headers: { 'Authorization': `Bearer ${token}` }
      //   })
      //   .then(resp => console.log(resp))
      //   .catch(err => {
      //     console.log(err.response)
      //   })
      // },
        
      clearForm() {
        this.form.is_legal = true
      },
      saveData() {
        if(this.isEditable && !this.accountAutocomplete) {
          this.addNewAccount()
        } else if (this.isEditable && this.accountAutocomplete) {
          this.editAccount()
        } else {
          this.addNewAccount()
        }
      },
      closeDialog() {
        this.$store.dispatch('Dialog/closeDialog')
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
      },
      generatePromo() {
        let chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let strings = [];
        let str = "";
        let length = Math.floor(Math.random() * (10 - 6 + 1)) + 6;
        for (let i = 0; i < length; i++) {
          let index = Math.floor(Math.random() * chars.length);
          str += chars[index];
        }
        if (strings.includes(str)) {
          return this.generatePromo();
        } else {
          strings.push(str);
          this.form.promo = str
          return str;
        }
      },
      changeDate(val) {
        if (val) {
        //   console.log(val)
        //   const data = {
        //   // id: this.activeAccount.id,
        //   params: {
        //     date_from: this.fixDateFormatNumbers(val.startDate),
        //     date_to: this.fixDateFormatNumbers(val.endDate)
        //   }
        // }
        // this.$store.dispatch('Ad/getStatistic', data)
        // console.log('data', data)
        this.form.available_at = this.fixDateFormatNumbers(val.startDate)
        this.form.expired_at = this.fixDateFormatNumbers(val.endDate)
        }
      },
      fixDate(start, end) {
        if (start && end) {
          let str = ''
          const startDay = start.getDate()
          const endDay = end.getDate()

          const startMonth = start.getMonth()
          const endMonth = end.getMonth()

          let nameStartMonth, nameEndMonth
          if (this.$i18n._vm.locale === 'ru') {
            nameStartMonth = this.monthsRu.find(el => el.id === startMonth).shortName
            nameEndMonth = this.monthsRu.find(el => el.id === endMonth).shortName
          } else if (this.$i18n._vm.locale === 'en') {
            nameStartMonth = this.monthsEn.find(el => el.id === startMonth).shortName
            nameEndMonth = this.monthsEn.find(el => el.id === endMonth).shortName
          }

          // const nameStartMonth = this.months.find(el => el.id === startMonth).shortName
          // const nameEndMonth = this.months.find(el => el.id === endMonth).shortName

          if (startDay === endDay && startMonth === endMonth) {
            str = startDay + ' ' + nameStartMonth
          } else if (startMonth === endMonth) {
            str = startDay + '-' + endDay + ' ' + nameStartMonth
          } else {
            str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
          }

          return str
        }
      },
      fixDateFormatNumbers(fixedDate) {
        if (fixedDate) {
          let date = new Date(fixedDate)
          let str = ''
          const startDay = String(date.getDate()).padStart(2, '0')
          const startMonth = String(date.getMonth() + 1).padStart(2, '0');
          const startYear = date.getFullYear()
          str = startYear + '-' + startMonth + '-' + startDay
          return str
        }
      },
      getNewStatistic() {
        // const data = {
        //   id: this.activeAccount.id,
        //   params: {
        //     date_from: this.fixDateFormatNumbers(this.date.startDate),
        //     date_to: this.fixDateFormatNumbers(this.date.endDate)
        //   }
        // }
        this.form.available_at = this.fixDateFormatNumbers(this.date.startDate)
        this.form.expired_at = this.fixDateFormatNumbers(this.date.endDate)
      },
      formValidation() {
        if(!this.form.promo) {
          this.errors.promo = this.$t('promo_data.error_input_promo')
          return
        } else {
          this.checkPromo()
        }
        if(this.form.is_personal) {
          if(!this.form.business_accounts || !this.form.business_accounts.length ) {
            this.errors.selectedAccounts = this.$t('promo_data.error_business_account')
            return
          }
        }
        if(!this.form.promoValue) {
          this.errors.promoValue = this.$t('promo_data.error_promosum')
          return
        }
        if (!this.form.available_at && !this.form.expired_at) {
          this.errors.sum_available = this.$t('promo_data.error_sum_available')
          return
        }
        if(!this.form.remark || !this.form.remark.length) {
          this.errors.remark = this.$t('promo_data.error_remark')
          return
        }
        if(this.form.remark) {
          if(typeof this.form.remark !== 'string') {
            this.errors.remark = this.$t('promo_data.error_remark_string')
            return
          } 
        }
        // if(!this.form.condition_sum) {
        //   this.errors.condition_sum ='Укажите условия работы промокода'
        //   return
        // }

        if(this.form.condition_sum) {
          if(typeof Number(this.form.condition_sum) !== 'number' && !Number.isNaN(this.form.condition_sum)){
            this.errors.condition_sum = this.$t('promo_data.error_condition_sum_2')
            return
          }
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";

  .selector.multi-search-selector {
    position: relative;
    width: 100%;

    @include below($sm) {
        max-width: 100%;
    }

    .selector__content {
        background: $backgroundInput;
        border: 1px solid $border;
        border-radius: 2px;
        height: 5rem;
        position: relative;
        padding: 0.7rem 1.5rem;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .selector__title {
        font-size: 1.6rem;
        line-height: 1.4rem;
        color: $basicBlack;
        margin-bottom: 0.2rem;
    }

    .selector__value {
        font-size: 1.6rem;
        line-height: 1.9rem;
    }

    .selector__arrow {
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;

        img {
            transition: 0.3s ease-out;
        }

        &--open {
            img {
                transform: rotate(180deg);
            }
        }
    }

    .selector__options {
        position: absolute;
        top: calc(100% + 1rem);
        left: 0;
        width: 100%;
        background: $backgroundInput;
        border: 1px solid $border;
        border-radius: 2px;
        z-index: 2;
        overflow: auto;
        max-height: 30rem;

        &-item {
            cursor: pointer;
            transition: 0.3s ease-out;
            padding: 0.7rem 1.5rem;
            margin: .3rem 0;

            &:hover {
                background: var(--primary);
                color: $basicWhite;
            }

            &--active {
                background: var(--primary);
                color: $basicWhite;
            }
        }
    }

    @include below(769px) {
      .selector__content {
            width: 100%;
        }
    }
}


  .dialog-add-promo-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__calendar {
      display: flex;
    }

    &__form-input {
      margin-top: 0;
      position: relative;
      width: 100%;
      transition: .3s;
    }

    .promo-values-group {
      display: flex;
      // flex-wrap: wrap;
      justify-content: flex-start;
      input {
        display: none;
      }
      .promo-amount {
        &.active {
          background-color: #D6E0F2;
        }
        padding: 1rem 3.5rem;
        border-radius: 0px 2px 2px 0px;
        border: 1px solid var(--grey-3, #BFC2C6);
        cursor: pointer;
      }
    }

    &__title {
      font-family: 'Roboto';
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.3rem;
      text-align: left;
    }
    &__description {
      font-family: 'Roboto';
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      text-align: left;
    }
    &__checkbox {
      .input__item-checkbox__title {
        font-family: 'Roboto';
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-align: left;
      }
    }
    &__form {
      &-block {
        margin: 0;
      }
      &-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -0.75rem;
      }
      &-col {
        width: 50%;
        padding: 0 0.75rem;
        margin-bottom: 0;
        .button {
          height: 5rem;
          width: 100%;
          font-family: 'Roboto';
          font-size: 1.6rem;
          font-weight: 700;
        }
       &--padding-0 {
        padding: 0;
       }
      }
    }
    &__selector{
      &-wrapper {
        display: flex;
        gap: 1.2rem;
        .selector {
          width: 100%;
        }
        .promo-button {
          border: none;
          width: 5rem;
          height: 5rem;
          background-color: var(--primary);
          border-radius: 5px;
          color: #fff;
          background-image: url('../../assets/icons/icon-cross-straight.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      } 
      .selector__content {
        width: 100%;
      }
    }
    &__info {
      padding: 1.5rem;
      border-radius: 2px;
      border: 1px solid #D1D4D8;
      overflow: auto;
      min-height: 12.5rem;
      color: #333;
      font-family: 'Roboto';
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      resize: none;
      &:focus {
        outline: none;
        
      }
   
    }
  }

  .dialog-add-promo {
    padding: 0.5rem;
    color: #333;

    &-section {
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      }

      // input[type="number"] {
      // -moz-appearance: textfield;
      // }

    }

    &__sections {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  
    &__title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.5rem;
      color: $basicBlack;
      margin-bottom: 1.5rem;
    }
  
    &__desc {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $basicBlack;
      margin-bottom: 3rem;
    }
    &__section {
      &-title {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0px;
        text-align: left;
      }
      &__form-input {
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

      //   input[type="number"] {
      //   -moz-appearance: textfield;
      // }

      }
    }


    @include below(577px) {
      padding: 32px 0 0;
  
      &__title {
        font-size: 18px;
        text-align: center;
        line-height: 1.4;
        margin-bottom: 16px;
  
        br {
          display: none;
        }
      }
  
      &__desc {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 16px;
        text-align: center;
      }
  
      &__show-settings {
        margin-bottom: 16px;
  
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
  
        span {
          font-size: 14px;
        }
      }
  
      &__form {
  
        .form-input {
          margin-bottom: 0;
        }
  
        &-title {
          font-size: 16px;
          margin-bottom: 12px;
        }
  
        &-block {
          margin-bottom: 16px;
        }
  
        &-row {
          margin-right: -16px;
          margin-left: -16px;
        }
  
        &-col {
          width: 100%;
          padding-right: 16px;
          padding-left: 16px;
          margin-bottom: 12px;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
  
        &-agree {
          margin-top: 0;
        }
  
        select {
          height: 5rem;
        }
  
        &-buttons {
          margin-top: 16px;
  
          .button {
            font-size: 14px;
            line-height: 1.4;
            height: auto;
            padding: 8px 12px;
  
            &:first-of-type {
              width: calc(50% - 6px);
              margin-right: 12px;
            }
  
            &:last-of-type {
              width: calc(50% - 6px);
            }
          }
        }
      }
    }
}

  </style>
  